import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import iPad01 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(16).webp'
import iPad02 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(17).webp'
import iPad03 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(18).webp'
import iPad04 from '../../../../../images/Software/iOS/InstarVision/iPad/IPad_App_NEW_(19).webp'


const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Press the Multiview icon to see an overview of all your cameras. Here only single images are taken from the cameras at a fixed interval."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Press a camera to tap its live video stream. A double tap on the video will display the function buttons or activate the swipe control for PTZ cameras."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Double click on the video to display all function buttons. A description of the buttons can be found below or directly in the help menu of the app."
        />
        <Slide
          media={
            <img alt="InstarVision Android App" src={iPad04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Multiview"
          subtitle="Press the gear icon below the live stream to display the video settings. Reduce the quality if the stream stops."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function MultiviewGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}

export default MultiviewGallery